@import url('https://fonts.googleapis.com/css2?family=Neonderthaw&family=Sen:wght@400;700;800&display=swap');
 .modalHeader {
    padding-bottom: 0px;
    margin-bottom: 20px;
    border-bottom: 1px solid #dddd;
  }
  .field-row {
    margin-bottom: 20px;
  }
  label {
    display: block;
    margin-bottom: 0px;
  }
  .btn-100 {
    width: 100%;
    border: 0px !important;
  }
  .closeMark {
    position: absolute;
    right: 15px;
    top: 15px;
    line-height: 30px;
    width: 30px;
    text-align: center;
    background-color: #f15f8d;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
  }
  

 

  .emplistSearch1 {
    font-family: "sen", sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #333333;
  }
  .leftSidebarOrg .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      height: 0px;
      opacity: 0;
    }
    .leftSidebarOrg  .ant-select-arrow {
      opacity: 0;
    }  
    .leftSidebarSelected {
      padding: 10px 10px;
      border: 1px solid #dddd;
      font-size: 12px;
      border-radius: 5px;
    }

    // .leftSidebarSelected {
    //   padding: 10px;
    //   border: 1px solid #dddd;
    //   font-size: 12px;
    //   border-radius: 5px;
    //   //background-color: rgba(241, 107, 149, 0.7);
    //   color: rgba(241, 107, 149, 0.7)
    // }
    
    // .leftSidebarSelected:hover {
    //   border: 1px solid #f15f8d;
    //   color: rgba(241, 107, 149, 0.7)
    // }
    
    // .leftSidebarSelected:focus {
    //   outline: none; /* Remove the outline when the element is focused */
    // }
    
    // .leftSidebarSelected:active {
    //   transform: scale(0.95); /* Apply a slight scale-down effect when the element is active */
    // }
    