.btn.btn-transparent.btn-remove .removebdr {
    width: 20px;
    display: inline-block;
    background: #f15f8d;
    color: #fff;
    font-size: 12px;
    line-height: 20px;
    position: relative;
    top: -1px;
    border-radius: 4px;
  }
  .btn.btn-transparent.btn-remove {
    border-radius: 6px;
  }
  .btn.btn-transparent.btn-remove:hover .removebdr {
    background-color: #ca3535;
  }
  .ant-select-item-option-disabled {
    color: #00000040;
    cursor: not-allowed;
    opacity: .5;
  }
  .inlineStyleListLocation {
    display: inline-block;
    width: auto;
    margin-right: 15px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    padding-left: 15px;
    height: 30px;
    line-height: 30px;
  }
  .inlineStyleListLocation .btn-transparent {
    width: 30px;
    background: #101535;
    border: 0px;
    height: 30px;
    overflow: hidden;
    fill: #fff;
    text-align: center;
  }
  .inlineStyleListLocation .btn-transparent.btn-delete {
    background: #e7497e;
    fill: #fff;
  }
  .inlineStyleListLocation .btn-transparent svg {
    max-width: 15px;
  }
  .inlineStyleListLocation .btn-transparent.btn-delete {
    margin-left: 15px;
  }
  .dleeteBtn button {
    width: 40%;
    margin-right: 4%;
    background: transparent;
    color: #f15f8d;
    border: 1px solid #f15f8d !important;
    line-height: 30px;
  }
  .dleeteBtn .btn.btn-primary.btn-100.btn-deletee {
    background: #f15f8d;
    color: #fff;
    margin-right: 0;
  }
  .dleeteBtn {
    text-align: center;
    margin-bottom: 20px;
  }
  .modalBody.modalBodyDelete p {
    font-size: 20px;
    text-align: center;
    margin: 35px 0px;
  }
  .modalBody.modalBodyDelete p strong {
    color: #f15f8d;
    font-weight: normal;
    font-size: 24px;
  }
  
/* Add styles for the tooltip container */
.tooltip {
  position: absolute;
  // top: 140%;
  // left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 4px 8px;
  border-radius: 8px;
  font-size: 8px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s;
}


/* Add styles to make the tooltip visible on hover */
.tooltip-active .tooltip {
  opacity: 60;
}
