.inlineTitle {
    display: inline-block;
    border: 0px;
    font-weight: bold;
    text-transform: uppercase;
    background: transparent;
}

.inlineTitle:focus {
    border: 0px;
    outline: none !important;
}