.whiteBgInner {
    background: #fff;
    border-radius: 4px;
}

.whiteBgInner .headerDevice {
    background: #fff;
    padding: 10px 15px;
    border-bottom: .1px solid #b8b8b8;
    /* border-top: 1px solid #ddd; */
}

.whiteBgInner .contentArea {
    padding: 10px;
}

.headerDevice h3 {
    margin: 0px;
    font-size: 14px;
}

.headerDevice h3 strong {
    font-weight: normal;
}

.AgTableGateway .ag-overlay-no-rows-center {
    position: absolute;
    top: 40px;
    position: absolute;
}

.badgeStatus {
    position: relative;
    text-transform: capitalize;
    color: red;
    font-size: 12px;

}

.AgTableGateway .badgeStatus {
    line-height: 32px;
}

.badgeStatus::before {
    content: "";
    position: relative;
    width: 6px;
    height: 6px;
    background: red;
    border-radius: 50%;
    display: inline-block;
    margin-right: 3px;
}

.badgeStatus.activeBadge {
    color: green;
}

.badgeStatus.activeBadge::before {
    background: green;
}

.nameAlign strong {
    font-size: 12px;
}

.clockWIthTIme {
    padding: 5px;
    width: 100px;
    background: #ef5e8c;
    color: #fff;
    border-radius: 6px;
    float: right;
}

.clockWIthTIme svg {
    fill: #fff;
    text-align: center;
    animation: rotate 5s linear infinite;
}

.highlightDeviceStatus {
    width: 6px;
    height: 6px;
    margin-right: 4px;
    border-radius: 50%;
    background-color: green;
    position: relative;
    display: inline-block;
    margin-top: -2px;
    top: -2px;
    /* animation: blink 3s infinite; */
}

.whiteBgInner {
    height: 100%;
}

@keyframes blink {

    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }
}

@keyframes beat {
    to {
        transform: scale(1.4);
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.customAccord.customAccordDevice .ant-collapse-item .ant-collapse-header {
    padding: 3px 15px;
}

.customAccord.customAccordDevice .ant-collapse-item .col {
    font-size: 12px;
    line-height: 22px;
}

.customAccord.customAccordDevice .ant-collapse-item svg {
    max-width: 9px;
    margin-top: -7px;
}

.AgTableGateway .ag-header,
.AgTableGateway .ag-header * {
    height: 35px !important;
    max-height: 35px;
    line-height: 35px !important;
    min-height: 35px !important;
    font-size: 12px;
}

.AgTableGateway .ag-cell.ag-cell-not-inline-editing.ag-cell-normal-height.ag-cell-value {
    line-height: normal;
    vertical-align: middle;
    padding-top: 4px;
    font-size: 13px;
}

.AgTableGateway .ag-theme-alpine .ag-row .ag-cell:first-child div {
    height: 100%;
    line-height: 1.3;
}

.ant-collapse-content-box .ant-collapse.ant-collapse-icon-position-start {
    border: 0px;
}

.deviceCount {
    margin-right: 12px;
}

.headerDevice h3 {
    line-height: 30px;
}

h3 .form-control {
    line-height: 0px !important;
    padding: 10px 10px;
    height: 30px;
    font-size: 12px;
    box-shadow: 2px 2px 20px rgba(236, 242, 249, 0.62);
}

.AgTableGateway.AGTablePersonal .ag-theme-alpine .ag-row {
    height: 30px !important;
    min-height: 30px !important;
}