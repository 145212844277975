@import url('https://fonts.googleapis.com/css2?family=Neonderthaw&family=Sen:wght@400;700;800&display=swap');
*,body{font-family: 'Sen', sans-serif;}
.logoutModal {
  background-color: #f9fafa;
  .logoutTitle {
      text-align: center;
      font-size: 1.5rem;
      font-weight: bold;
      line-height: 1.7;
      padding: 20px 0 10px;
  }

  .logoutDesc {
    font-size: 13px;
    font-weight: normal;
    text-align: center;
    padding: 10px 0 42px;
    span.counttimer{
      color:#f73e5d !important;
      font-weight: 700;
    }
  }

  .actionButtons {
      display: -webkit-box;
      border-top: 0.063rem solid #ebeced;
      text-align: center;
      cursor: pointer;
      float: left;
      width: 100%;

      .btnText {
          font-size: 1.125rem;
          display: block;
          text-align: center;
          text-decoration: none;
          line-height: 1.7;
          padding: .65em 0;
          width: 49.7%;
          color: #7c828a;
      }

      .okBtn {
          color: #f73e5d !important;
      }

      .borderRight {
          border-right: 0.063rem solid #ebeced;
      }
  }
}
.logoutModal .logoutDesc {
  font-size: 13px;
  font-weight: normal;
  text-align: center;
  padding: 10px 0 42px;
}
iframe {
  display: none;
}
.main-sidebar {
  width: 400px;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  background:#ecf2f9 !important;
  padding-right: 13px;
  max-height: 100%;
  overflow-y: scroll;
}
// .main-sidebar{
//   overflow: scroll; 
//   width: 100%;
//   height: 100%;
//   -ms-overflow-style: none;  
//   scrollbar-width: none;  
// }


// .main-sidebar::-webkit-scrollbar {
//   width: 0;
//   height: 0;
//   background-color: transparent;
// }
.main-sidebar::-webkit-scrollbar {
  width: 4px;
  background-color: #F5F5F5;
}

// .main-sidebar::-webkit-scrollbar-thumb {
//   background-color: #000000;
// }

.main-sidebar::-webkit-scrollbar-thumb:hover {
  background-color: rgb(240, 124, 163);
}

@media only screen and (max-width: 768px) {
  
  .main-sidebar {
    width: 350px;
    position: relative;
    max-height: 50%;
  }
}
.main-content.side-content .page-header {
  padding: 10px;
  background: #fff;
  border: 1px solid #e1e1c5;
}

.main-sidebar {
  width: 230px;
  position: fixed;
  background: #fff !important;
  // border-right: 1px solid #ebebeb;
  .main-sidebar-body .nav-item svg {
    max-width: 15px;
  }
  
  .sidemenu-logo {
    padding: 20px 20px;
    text-align: center;
    margin-bottom: 30px;
    margin-top: 15px;
  }
  li.nav-item {
    float: left;
    width: 100%;
    border-radius: 0px 25px 25px 0px;
    margin-bottom: 10px;
    
    a {
      color: #505a5f;
      padding:10px 20px;
      padding-right: 0px;
      .sidemenu-icon{
        padding-right:15px;
      svg {
        fill: #8f9496;
      }
    }

    }
    

  }
  li.nav-item:hover{
    background-color: #ffeef3;
    a {
      color: #f16b95;
      span{
        color: #f16b95;
      }
      .sidemenu-icon{
        padding-right:15px;
      svg {
        fill:#f16b95;
      }
    }
    }
  }
  li.nav-item.active{
    background-color: #ffeef3;
    a {
      color: #f16b95;
      span{
        color: #f16b95;
      }
      .sidemenu-icon{
        padding-right:15px;
      svg {
        fill:#f16b95;
      }
    }
    }
  }
  .main-sidebar li.nav-item.active a .nav-item {
    color: #f16b95 !important;
  }
  .main-sidebar li.nav-item:hover a .nav-item{
    color: #f16b95 !important;
  }

}


.responsive-logo{display:none;}

.main-sidebar {
  z-index: 9;
}
.main-sidebar li.nav-item {
  position: relative;
}
.main-sidebar li.parentNav.nav-item, .main-sidebar li.parentNav.nav-item li {
  border-radius: 0px;
}
