    @media only screen and (max-width: 1400px) {
      .contentGroup .group-header .align-middle.col-lg-3:last-child,
      .headerGroup .align-middle.col-lg-3:last-child {
        min-width: 275px;
      }
      .main-sidebar {
        width: 200px;
        position: fixed;
      }
      .main-header.side-header {
        padding: 10px 20px 0px 220px;
        }
        .main-content.side-content {
          padding-left: 220px;
        } 
        .main-header-profile .col-lg-7, .main-header-profile .col-lg-5 {
          max-width: 50%;
          flex: 0 0 58.333333%;
        }
        .inner-body .page-header .header-btn.col-lg-4 {
          width: 50%;
          max-width: 50%;
          flex: 0 050%;
        } 
        .inner-body .page-header .col-lg-6 {
          max-width: 50%;
        }
        .inner-body .page-header .col-lg-2 {
            display: none;
        }
        .main-sidebar li.nav-item a {
          font-size: 13px;
        }
        .contentCategory .align-title-left h4 {
          font-size: 14px;
         }
        
         .btn.btn-smaller {
          font-size: 13px;
          }
          .accordion-icon svg {
            max-width: 10px;
          }
          .roleList .headerCategory.headerGroup.page-header .col-lg-8, .roleList .headerCategory.headerGroup.page-header .col-lg-4 {
            width: 50%;
            max-width: 50%;
            text-align: right;
          }
    }

    /*Small Desktop */
    @media only screen and (max-width: 1200px) {
      .roleList .headerGroup.row .align-with-title {
        max-width: 30px;
      }
      .roleList .headerGroup.row .align-middle.col-lg-6 {
        max-width: 35%;
      }
      .roleList .headerGroup.row {
        font-size: 14px;
        letter-spacing: .5px;
      }
      .roleList .contentGroup .align-middle.col-lg-6 {
        max-width: 35%;
      }
      .roleList .contentGroup.row {
        font-size: 14px;
        letter-spacing: .5px;
      }
      .contentGroup .align-with-title{
        max-width: 30px;
      }
      
      .main-content-title {
        margin-bottom: 5px;
        font-size: 20px;
      }
      .main-header-profile .col-lg-5 {
        max-width: 60%;
      }
      .main-header-profile .col-lg-7 {
        max-width: 40%;
      }
      .roleList .headerCategory.headerGroup.page-header .col-lg-4 {
        width: 60%;
        max-width: 60%;
      }
      .roleList .headerGroup.row {
        font-size: 12px;
        letter-spacing: 0;
      }
      .headerGroup .align-middle.userManagementLast.col-lg-5, .contentGroup .align-middle.userManagementLast.col-lg-5 {
        min-width: 380px !important;
        max-width: 380px !important;
      }
      .inner-body .page-header .header-btn.col-lg-4 {
        width: 100%;
        max-width: 100%;
        flex: 0 1 100%;
      }
      .contentGroup .group-header .align-middle:last-child {
        text-align: left;
      }
      .contentGroup .align-middle button.btn.btn-smaller.btn-icon {
        line-height: 30px;
      }
      body .headerGroup .align-middle.userManagementLast.col-lg-5,
      body .contentGroup .align-middle.userManagementLast.col-lg-5 {
       
        min-width: 380px !important;
        max-width: 380px !important;
       }
       .align-middle.userManagementLast .btn.btn-smaller.btn-icon {
        display: inline-block;
        text-align: center;
        width: 23%;
        font-size: 12px;
        padding: 0px !important;
      }
      .contentGroup .align-middle.userManagementLast button.btn.btn-smaller.btn-icon:first-child {
        padding-left: 0px !important;
      }
      .contentGroup .align-middle.userManagementLast .btn.btn-smaller.btn-icon svg {
        float: none;
        position: relative;
      }
      .contentGroup .align-middle.userManagementLast button.btn.btn-smaller.btn-icon {
        padding-left: 0px !important;
      }
      .contentGroup .align-middle.userManagementLast button.btn.btn-smaller span {
        width: 100%;
      }
      .headerCategory .align-middle.siteManagementlast, .contentCategory .align-middle.siteManagementlast {
        max-width: 315px;
        min-width: 315px;
        float: right;
      }
    }


    @media only screen and (max-width: 1023px) and (min-width:992px) {
         }
    

    @media only screen and (max-width:1023px) {
      .container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl {
        padding-right: 20px;
        padding-left: 20px;
      }
      .main-sidebar.main-sidebar-sticky.side-menu.menu-active {
        left: -230px;
      }
      .main-sidebar {
        transition: all ease-in 0.3s;
        background-color: #fff !important;
        z-index: 99;
        border-right: 1px solid #ddd;
        -webkit-box-shadow: 7px 0px 5px -5px #f0efefcc;
        -moz-box-shadow: 7px 0px 5px -5px #f0efefcc;
        box-shadow: 7px 0px 5px -5px #f0efefcc;
      }
      .responsive-logo .mobile-logo {
        max-width: 120px;
        margin: 7px 0px;
      }
      .main-header.side-header.sticky {
        padding: 0px;
        overflow: auto;
        border-bottom: 1px solid #ddd;
        padding-bottom: 10px;
        -webkit-box-shadow: 7px 11px 16px -5px #c9b5c942;
        -moz-box-shadow: 7px 11px 16px -5px #c9b5c942;
        box-shadow: 7px 11px 16px -5px #c9b5c942;
      }
      .main-content.side-content {
        padding-left: 15px;
        width: 100%;
        float: left;
      }
      .responsive-logo {
        display: block;
      }
      .main-header-center {
        float: left;
        width: 25%;
      }
      .main-header-profile {
        float: left;
        width: 70%;
      }
      .main-header-profile .col-lg-7 {
        float: left;
        width: 60%;
      }
      .main-header-profile .col-lg-5 {
        width: 40%;
        float: left;
      }
      .main-content-title {
        margin-bottom: 0px;
        font-size: 18px;
      }
      .icon-row .avatar-size {
        max-width: 40px;
      }
      .icon-row .author-name {
        font-size: 14px;
        padding-left: 15px;
      }
      .icon-row {
        padding: 0px 10px;
       }
       .main-sidebar {
        transition: all ease-in 0.3s;
      }
      .roleList .headerGroup.row .align-middle.col-lg-6 {
        max-width: 50%;
      }
      .roleList .contentGroup.row .align-middle.col-lg-6 {
        max-width: 50%;
      }
      .contentGroup .group-header .align-middle.col-lg-3:last-child, .headerGroup .align-middle.col-lg-3:last-child {
        min-width: 150px !important;
      }
      .contentGroup .group-header .align-middle:last-child, .headerGroup .align-middle:last-child {
        min-width: 150px !important;
      }
      .btn.btn-smaller.btn-icon span {
        display: none;
      }
      .main-header-left {
        display: block;
      }
      .box-stat-rows.row .box-space-bottom.col-lg-4:nth-child(2n) {
        padding-right: 0px;
      }
      .box-space-bottom.col-lg-4 {
        max-width: 49%;
        padding-left: 0px;
        padding-right: 15px;
      }
      .main-header-profile {
        position: absolute;
        top: 67px;
        left: 0;
        right: 0;
        width: 100%;
        background: #fbfbfb;
        padding: 10px 20px;
        border-bottom: 1px solid #ddd;
      }
      .main-content.side-content {
        padding-top: 70px !important;
        padding-left:0px !important;
      }
      .main-header.side-header .page-header .breadcrumb {
        background: transparent;
      }
      body .headerGroup .align-middle.userManagementLast.col-lg-5, body .contentGroup .align-middle.userManagementLast.col-lg-5 {
        min-width: 220px !important;
        max-width: 378px !important;
      }
      .contentGroup .align-middle button.btn.btn-smaller.btn-icon svg {
        left: 0px;
      }
      body .headerGroup .align-middle.userManagementLast.col-lg-5, 
      body .contentGroup .align-middle.userManagementLast.col-lg-5 {
        min-width: 190px !important;
        max-width: 378px !important;
        padding-left: 10px;
      }
      body .align-middle.userManagementLast .btn.btn-smaller.btn-icon {
        width: 25px;
        font-size: 12px;
        padding: 0px !important;
      }
      body .roleList .align-middle.col-lg-3 {
        min-width: 200px;
      }
      body .contentGroup button.btn.btn-icon svg {
        position: relative;
      }
      body .contentGroup .align-middle button.btn.btn-smaller.btn-icon:first-child {
        padding-left: 0px !important;
      }
      body .contentGroup .align-middle button.btn.btn-smaller.btn-icon {
        width: 30px !important;
        padding: 0px !important;
      }
      body .roleList .headerGroup.row {
        margin-left: 0px !important;
      }
      body .roleList .contentGroup.row {
        margin-left: 0px;
      }
      body .contentGroup .group-header .align-middle {
        padding-left: 15px;
      }
      body .contentCategory .group-header .align-middle {
        padding-left: 15px;
      }
      body .roleList.deviceManagement .align-middle {
        min-width: 140px;
      }
      .header-table-2.row .label-check.col-lg-2 {
        width: 15%;
        padding: 4px 10px;
      }
      .header-table-2.row .label-disp.col-lg-4 {
        width: 40%;
        padding: 4px 10px 0px 40px;
      }
      .bg-background-accord .icon-right {
        float: right;
        position: absolute;
        left: 13px;
        top: 3px;
      }
      .cotnent-table.row .label-disp.col-lg-4 {
        width: 40%;
        padding: 4px 10px;
      }
      .cotnent-table.row .label-check.col-lg-2 {
        width: 15%;
        padding: 4px 10px;
      }
      .bg-background-accord .icon-right svg {
        width: 14px !important;
        fill: #ee799e;
      }
      .btn.btn-transparent.btn-icon {
        padding: 5px 10px;
       }
       .btn.btn-transparent.btn-icon {
        padding: 5px 10px 5px 30px !important;
        font-size: 14px;
      }
      h2 {
        font-size: 1.5rem;
      }
      .btn.btn-large.btn-header.btn-transparent.btn-icon {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
      }
      .headerGroup.row {
        padding: 0px;
      }
      button.btn.btn-icon svg {
        left: 6px;
        }
        .headerGroup.row .align-middle {
          line-height: 35px;
          background: #f4f4f4;
          border: 1px solid #e6e1e1;
        }
        
        .bg-background-accord .icon-right {
          top: 4px;
          width: 10px;
        }
        .ant-steps.ant-steps-horizontal.ant-steps-label-horizontal {
          max-width: 90%;
         }
        
        .steps-content .col-lg-6 {
          max-width: 50%;
        }
        .steps-content .col-lg-6:nth-child(2n) {
          padding-left: 0px;
        }
        .steps-content .shiftInResponsive.col-lg-6 {
          width: 100%;
          max-width: 100%;
        }
        .steps-content .shiftInResponsive .col-lg-4 {
          max-width: 33.333%;
        }
        .steps-content .header-table-2.header-table-3.row .col-lg-3 {
          width: 25%;
          font-size: 12px;
          text-align: center !important;
          vertical-align: middle;
          line-height: 24px;
          padding: 5px 10px;
        }
        .steps-content .cotnent-table.row .col-lg-3 {
          width: 25%;
          line-height: 1;
          padding: 5px 10px !important;
        }
        .inner-body .page-header .col-lg-6.header-btn.col-lg-6 {
          width: 100%;
          max-width: 100%;
        }
        .header-table-2.header-table-3.header-table-4.row .label-check, 
        .header-table-2.header-table-3.header-table-4.row .label-disp,
        .header-table-2.header-table-4.row .label-disp,
        .header-table-2.header-table-4 .label-check,
        .header-table-2.header-table-4.row .label-check.col-lg-2 {
          width: 25%;
        }
        
        .bg-background-accord .icon-right {
          top: 3px;
        }
}
 

@media only screen and (max-width:767px) {
}

@media only screen and (max-width:480px) {
}