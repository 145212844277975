.modalBody .MuiFormControl-root.MuiTextField-root {
  width: 100%;
}

.modalBody .MuiOutlinedInput-root.MuiInputBase-root input {
  padding: 8px 20px;
}

.modalBody .MuiOutlinedInput-notchedOutline legend {
  display: none;
}

.modalBody .MuiOutlinedInput-notchedOutline {
  top: 0px;
}

.input-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.input-item {
  width: 48%;
  margin-bottom: 10px;
}

.timezone-picker-container {
  width: 100%;
}

.timezone-picker {
  width: 100%;
  max-height: 200px;
  display: flex;

}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 3px;
}

.custom-select-dropdown {
  backdrop-filter: none !important;
  z-index: 10002 !important;
  /* Increase the z-index value to bring the dropdown options above the blur */
}

.select-container {
  position: relative;
  z-index: 10001;
  /* Increase the z-index value to bring the select container above the overlay */
}

.orgList {
  display: grid;
  grid-template-columns: auto auto auto;
  background-color: transparent;
  column-gap: 25px;
  row-gap: 25px;
}

.orgBox .pinkBackground {
  text-align: center;
}

.orgBox {
  position: relative;
  display: block;
  background: #fff;
  background-position-x: 0%;
  background-position-y: 0%;
  background-repeat: repeat;
  background-image: none;
  float: right;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 0px 24px 24px rgba(237, 237, 237, 0.26);
  background-repeat: no-repeat;
  cursor: pointer;
}

.orgBox .bgIMage {
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-size: 200px;
  background-repeat: no-repeat;
  background-position: 146% 120px;
  z-index: 9;
  opacity: .7;
}


.lastArea div img {
  max-width: 15px;
}

.lastArea .row {
  margin-bottom: 6px;
}

.lastArea div.titleAreaTag {
  font-size: 14px;
  color: #101535;
}

.orgBox .innerSUb {
  position: relative;
}

.main-content.side-content .page-header.page-header2 {
  margin-bottom: 25px;
  -webkit-box-shadow: 0px 0px 24px 24px rgba(237, 237, 237, 0.26);
  -moz-box-shadow: 0px 0px 24px 24px rgba(237, 237, 237, 0.26);
  box-shadow: 0px 0px 24px 24px rgba(237, 237, 237, 0.26);
}

.innerSUbTitleArea h4 {
  text-transform: capitalize;
}

.innerSUbTitleArea h4 img {
  float: none;
  max-width: 120px;
  display: block;
  margin-bottom: 0px;
  margin-bottom: 15px;
}

.innerSUbTitleArea h4 {
  color: #040a4a;
  font-size: 18px;
  font-weight: 700;
}

.threeDotted {
  position: absolute;
  right: -10px;
  top: 0px;
}

.threeDotted img {
  max-width: 20px;
}

.innerSubContentArea .secondArea {
  width: 120px;
  display: inline ;
  border: 1px solid #f16b95;
  padding: 5px 15px;
  margin: 10px 0px;
  margin-right: 0px;
  margin-right: 0px;
  color: #f16b95;
  border-radius: 20px;
  font-size: 13px;
  margin-right: 5px;
  cursor: pointer;
}

.innerSubContentArea .secondArea.active {
  background: #f16b95;
  color: #fff;
}

.titleAreaTag.noBorder.col-lg-12 {
  margin-top: 13px;
}

.lastArea h5 {
  font-size: 16px;
}

.lastArea .col-lg-12 {
  padding-bottom: 5px;
  padding-left: 5px;
}

.lastArea div.titleAreaTag {
  font-size: 14px;
  color: #101535;
  background: #ebeff2;
  line-height: 30px;
  padding-bottom: 0px;
  margin-top: 5px;
  margin-bottom: 10px;

}

.popup {
  position: absolute;
  top: 120%;
  left: -80%;
  padding: 5px;
  background-color: #f1f1f1;
  border-radius: 4px;
  transition: transform 0.3s ease;
  z-index: 10;
}

.popup ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.popup ul li {
  cursor: pointer;
  color: #333;
  transition: background-color 0.3s ease;
}

.threeDotted.open .popup {
  transform: translateX(-100%);
}

.hoverable:hover .icon,
.hoverable:hover h2 {
  color: #f16b95
}

.loaderContainer1 {
  position: fixed;
  top: 40%;
  left: 50%;

}

.suborg-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.suborg-list li {
  margin-bottom: 10px;
  padding: 5px 10px;
  background-color: #f2f2f2;
  border-radius: 5px;
  cursor: pointer;
}



.suborg-list li:last-child {
  margin-bottom: 0;
}

.threeDotted1 {
  right: -150px;
  flex: 1;
  top: -40px;
}

.logourlclass {
  display: flex;
}

.suborg-list li {
  margin-bottom: 10px;
  padding: 5px 10px;
  background-color: #f2f2f2;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  transition: all ease-in 0.3s;
}

.arrowRIght {
  width: 30px;
  height: 30px;
  background: #fff;
  position: absolute;
  right: 10px;
  top: 0px;
  border-radius: 5px;
  border: 1px solid #f16b95;
  transition: all ease-in 0.3s;
}

.arrowRIght svg {
  max-width: 10px;
  fill: #f16b95;
  float: right;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  margin: auto;
  height: 10px;
  bottom: 0;
  transition: all ease-in 0.3s;
}

.suborg-list li:hover .arrowRIght {
  opacity: 1;
  right: 0px;
  background: #f16b95;

  // transition: all ease-in opacity 0s, right 0.3s;
}

.titleAreaTag.noBorder button svg {
  fill: #fff;
}

.suborg-list li:hover .arrowRIght svg {
  fill: #fff;
}

.titleAreaTag.noBorder button {
  background: #f16b95 !important;
  padding: 0px;
  padding-left: 0px;
  width: 20px;
  position: absolute;
  right: 10px;
  top: 0px;
  bottom: 0px;
}

.titleAreaTag.noBorder button:hover svg {
  fill: #fff;
}

.suborg-list li:hover {
  background-color: #ffd9e4;
}
.timeZoneArea{
  text-align: center;
}
.timeZoneArea svg {
  height: 450px;
  margin-top: 25px;
  margin-right: 99px;

}