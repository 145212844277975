.box-space-bottom.box-space-bottom-2 .headerStats svg {
  max-width: 80px;
  margin-bottom: 30px;
}

.box-space-bottom.box-space-bottom-2 .headerStats {
  margin-bottom: 0px;
  text-align: center;
}

.box-space-bottom.box-space-bottom-2 .noteArea {
  text-align: center;
  font-size: 17px;
  margin-top: 20px;
}

.box-space-bottom.box-space-bottom-2 .noteArea span {
  color: #f0739b;
}

.box-space-bottom.box-space-bottom-2 .boxDashboardStats .headerStats .smallerFont {

  font-size: 21px;
}

.box-space-bottom.box-space-bottom-2 .boxDashboardStats {
  padding: 90px 50px;
}

.box-space-bottom.box-space-bottom-2.col-lg-6 {
  padding: 0px 15px;
}

.box-space-bottom.box-space-bottom-2.col-lg-6:hover * {
  color: #fff;
  fill: #fff;
}

.box-space-bottom.box-space-bottom-2.col-lg-6:hover .boxDashboardStats {
  background: #f16b95;
}

.modalBody .noteArea {
  line-height: 1;
  margin-bottom: 24px;
  display: block;
}

.btn-rightPush {
  float: right;
  text-align: right;
  width: 100%;
  padding: 0px 20px;
}

.btn-rightPush span {

  margin-right: 10px;
}

.btn-rightPush .btn.btn-transparent {
  margin-right: 10px
}

.btn-rightPush .btn.btn-transparent.btn-twoline span {
  margin-right: 0px;
  display: block;
  line-height: 1;
  text-align: left;
}

.btn.btn-transparent.btn-twoline .smallerSpan {
  font-size: 10px;
  text-transform: uppercase;
}

.btn.btn-transparent.btn-twoline .biggerSpan {
  font-size: 13px;
}

.btn.btn-transparent.btn-twoline:hover span {
  color: #fff;
}

.posRel svg {
  width: 13px;
}

.overlayWrongFIle {
  position: relative;
  height: 23px;
  width: 100%;
  background: #fff;
  margin-top: -25px;
  margin-bottom: -10px;
}

.errorList {
  border-bottom: 1px solid #ddd;
  text-transform: capitalize;
}

.errorList strong {
  color: #520f0f;
  font-weight: normal;
}

.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: #f16b95;
}

.ant-modal-body .errorList:last-child {
  border-bottom: 0px;
}

.loader {
  position: relative;
  width: 100%;
  height: 20px;
  margin: 50px auto;
  overflow: hidden;
}

.arrow {
  position: absolute;
  width: 150px;
  height: 8px;
  background-color: #f15f8d;
  border-radius: 10px;
  animation: move 700ms infinite alternate;
  transition: all cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
  left: 0px;
}

.loaderinfinite {
  position: relative;
  height: 30px;
  overflow: hidden;
}

.ant-progress-success-bg,
.ant-progress-bg {
  position: relative;
  background-color: #f15f8d;
  border-radius: 100px;
  transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
}

.arrow-left {
  left: 0;
}


@keyframes move {
  from {
    left: 0;
  }

  to {
    left: 100%;

  }
}