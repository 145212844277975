.ant-btn.mapbox-gl-draw_polygon:empty,
.ant-btn.mapbox-gl-draw_point:empty,
.ant-btn.mapbox-gl-draw_trash:empty {
  width: 20px;
  height: 20px;
  padding: 0px;
  content: '';
  visibility: visible;
  background-color: #f8f8f8;
  border-color: #e2e2e2;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, .1);
}

.ant-btn.mapbox-gl-draw_polygon:empty:hover,
.ant-btn.mapbox-gl-draw_point:empty:hover {
  background-color: #f8f8f8;
  border-color: #e2e2e2;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, .1);
}

.ant-btn.mapbox-gl-draw_polygon:empty {
  background-image: url('data:image/svg+xml;utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" width="20" height="20">%3Cpath d="m15 12.3v-4.6c.6-.3 1-1 1-1.7 0-1.1-.9-2-2-2-.7 0-1.4.4-1.7 1h-4.6c-.3-.6-1-1-1.7-1-1.1 0-2 .9-2 2 0 .7.4 1.4 1 1.7v4.6c-.6.3-1 1-1 1.7 0 1.1.9 2 2 2 .7 0 1.4-.4 1.7-1h4.6c.3.6 1 1 1.7 1 1.1 0 2-.9 2-2 0-.7-.4-1.4-1-1.7zm-8-.3v-4l1-1h4l1 1v4l-1 1h-4z"/>%3C/svg>') !important;
}

.ant-btn.mapbox-gl-draw_point:empty {
  background-image: url('data:image/svg+xml;utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" width="20" height="20">%3Cpath d="m10 2c-3.3 0-6 2.7-6 6s6 9 6 9 6-5.7 6-9-2.7-6-6-6zm0 2c2.1 0 3.8 1.7 3.8 3.8 0 1.5-1.8 3.9-2.9 5.2h-1.7c-1.1-1.4-2.9-3.8-2.9-5.2-.1-2.1 1.6-3.8 3.7-3.8z"/>%3C/svg>') !important;
}

.ant-btn.mapbox-gl-draw_trash:empty {
  background-image: url('data:image/svg+xml;utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" width="20" height="20">%3Cpath d="M10,3.4 c-0.8,0-1.5,0.5-1.8,1.2H5l-1,1v1h12v-1l-1-1h-3.2C11.5,3.9,10.8,3.4,10,3.4z M5,8v7c0,1,1,2,2,2h6c1,0,2-1,2-2V8h-2v5.5h-1.5V8h-3 v5.5H7V8H5z"/>%3C/svg>') !important;

}

.ant-btn.mapbox-gl-draw_trash:empty:hover {
  background-color: red;
  border-color: red;
}

.ant-table-content * {
  font-size: 12px;
  text-align: center;
}

.ant-table-content .ant-table-cell {
  padding: 2px 8px !important;
}

.ant-table-content .ant-table-row .ant-table-cell:first-child {
  text-align: left;
}

.ant-btn.ant-btn-danger.ant-btn-sm.mapbox-gl-draw_trash[disabled] {
  opacity: .6;
  cursor: not-allowed;
  background-color: #ddd !important;
  border-color: #ddd !important;
}