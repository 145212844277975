.modalHeader {
    padding-bottom: 0px;
    margin-bottom: 20px;
    border-bottom: 1px solid #dddd;
  }
  .field-row {
    margin-bottom: 20px;
  }
  label {
    display: block;
    margin-bottom: 0px;
  }
  .btn-100 {
    width: 100%;
    border: 0px !important;
  }
  .closeMark {
    position: absolute;
    right: 10px;
    top: 10px;
    line-height: 20px;
    width: 20px;
    text-align: center;
    background-color: #f15f8d;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    border-radius: 4px;
  }
  .header-btn {
    text-align: right;
  }