.successToast{
  background: #00FF00 !important;
  color: #FFFFFF !important;
}

.errorToast{
  background: #ff0000 !important;
  color: #FFFFFF !important;
}

.ReactModal__Overlay{
  background: rgba(0,0,0,0.5) !important;
  box-shadow: none !important;
}
.header-table div {
  border-bottom: 1px solid #ddd;
  line-height: 40px;
  text-transform: uppercase;
  font-weight: bold;
}

