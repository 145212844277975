.form-control.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 0px;
  height: 33px;
}

.br-seperator {
  margin: 15px 0px;
  border-bottom: 1px solid #f1f3f4;
}

.bg-background-accord {
  border-radius: 0px;
  height: 100%;
  box-shadow: 0px 0px 5px 0px #fdfdfdbf;
  width: 100%;
  background: #fafafa;
  position: relative;

}


.header-table {
  margin: 0px;
}

.header-table div {
  border-bottom: 1px solid #ddd;
  line-height: 40px;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  border-left: 1px solid #ddd;
  font-size: 13px;
}

.versionInlineList .tootlipVersionImage {
  display: none;
  position: absolute;
  top: 20px;
  z-index: 99;
  background: #f4f4f4;
  border-radius: 5px;
  text-align: left;
  padding: 13px;
  color: #373434;
  font-size: 13px;
  max-width: 400px;
  z-index: 99;
  line-height: 1.2;
  box-shadow: 11px 18px 57px 10px rgba(85, 89, 124, .2);
  width: 400px;
}

.tootlipVersionImage img {
  max-width: 100%;
  height: auto;
}

.versionInlineList:hover .tootlipVersionImage {
  display: block;
}

.inlineVersion {
  display: table-cell;
}

.inlineVersion .versionInlineList {
  display: inline-block;
  width: 20px;
  margin-right: 10px;
  border-radius: 4px;
  border: 1px solid #d17691;
  text-align: center;
  color: #e7457b;
  font-size: 12px;
  cursor: pointer;
  height: 20px;
  line-height: 22px;
}


.contentGroup .group-content.active:hover {
  overflow: visible;
}

.headerGroup.headerGroupSubPages h4 {
  font-size: 14px;
  letter-spacing: 0px;
}

.headerGroup.headerGroupSubPages .inlineVersion .versionInlineList {
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 5px;
  border-color: #cdc8c8;
  margin-bottom: 3px;
  margin-right: 3px;
}

.headerGroup.headerGroupSubPages.row {
  padding: 2px 0px;
}


.btn.btn-submit-bigger.btn-primary {
  width: 300px;
}

.header-table div:last-child,
.cotnent-table div:last-child {
  border-right: 1px solid #ddd;
}

.accord-role {
  height: 0px;
  overflow: hidden;
}

.accord-role.active {
  height: auto;
}

.cotnent-table div:first-child {
  text-align: left;
  padding-left: 20px;
}

.cotnent-table.row {
  margin: 0px;
}

.cotnent-table div {
  line-height: 24px;
  padding: 10px 0px;
  text-align: center;
  border-left: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.bg-background-accord .icon-right {
  float: right;
  position: absolute;
  left: 20px;
  top: 10px;
}

.header-table-2.row .label-check {
  text-align: center;
  text-transform: uppercase;
}

.bg-background-accord .icon-right svg {
  max-width: 33px;
  width: 20px;
  fill: #ee799e;
}

.header-table-2.row {
  margin: 0px;
}

.header-table-2.row div:last-child {
  border-right: 1px solid #ddd;
}

.header-table-2.row div {
  padding: 10px 50px;
  border-left: 1px solid #ddd;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  text-transform: uppercase;
  font-weight: bold;
}

.btn.btn-submit-bigger {
  width: 300px;
}

.header-table-3.row .label-disp.col-lg-4,
.header-table-3.row .label-check {
  border-color: #978a8a !important;
}

.header-table-2.header-table-3.row {
  background: #dedbdb;
}

.header-table-2.row div .ant-switch div {
  padding: 0px;
  border-left: 0px;
  border-top: 0px;
  border-bottom: 0px;
  text-transform: uppercase;
  font-weight: bold;
}

.ant-switch-checked {
  background-color: #f16b95 !important;
}

.element-icon.ant-table-cell .btn.btn-edit.btn-smaller.btn-icon {
  padding-left: 20px !important;
}

.element-icon.ant-table-cell .btn.btn-edit.btn-smaller.btn-icon svg {
  left: 0px;
}

.form-relative {
  position: relative;
}

.loader-form {
  .formsArea {
    opacity: .5;
    cursor: not-allowed;
  }

  .loaderArea {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 200px;
    width: 200px;
  }
}

.timePickMy .MuiFormControl-root .MuiOutlinedInput-root {
  display: block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.switchRight {
  float: right;
  width: 80px;
  text-align: center;
}

.cotnent-table div.label-disp.col-lg-4:first-child {
  padding-right: 20px;
}

.cotnent-table div.label-disp.col-lg-4:first-child .switchRight div {
  border: 0px;
  padding: 0px;
}

.align-middle.role-management-act {
  text-align: left;
  max-width: 260px;
  float: right;
  text-align: center;
}

.header-table-2.row .label-check .smallFont {
  font-size: 10px;
  display: block;
}

.header-table-2.row .label-check .biggerFont {
  font-size: 14px;
}

.header-table-2.header-table-3.header-table-4.row .label-check {
  padding-left: 10px;
  padding-right: 10px;
}

.contentGroup .align-middle .posAbsoluteBtn button.btn.btn-smaller.btn-icon {
  width: 30px;
  height: 30px;
  border: 1px solid #e7457b !important;
  background-color: #e7457b !important;
  padding: 0px 10px !important;
  padding-left: 10px;
  text-align: center;
}

.contentGroup .align-middle .posAbsoluteBtn button.btn.btn-smaller.btn-icon:first-child {
  padding-left: 10px !important;
  margin-right: 10px;
}

.contentGroup .align-middle .posAbsoluteBtn button.btn.btn-smaller.btn-icon svg {
  left: 0px;
  right: 0px;
  fill: #fff !important;
}

.contentGroup .align-middle .posAbsoluteBtn button.btn.btn-smaller.btn-icon.btn-delete {
  background: #bd2727 !important;
  border-color: #bd2727 !important;
}

.posAbsoluteBtn {
  padding-bottom: 5px;
  border-bottom: 1px solid #cdcdcdde;
  margin-bottom: 9px;
}

.contentGroup .align-middle .posAbsoluteBtn button.btn.btn-smaller.btn-icon:hover {
  opacity: .85;
}