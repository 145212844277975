.mapboxgl-canvas {
  background: #14141a;
}

.absGreatStyle {
  position: absolute;
  bottom: 50px;
  left: 20px;
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
  text-align: center;
  // width: 575px;
  // width: 95px;
  height: 80px;
  cursor: pointer;
  transition: width ease-in 0.3s;
}


.absGreatStyle .boxStyle {
  display: inline-block;

  text-align: center;
  padding: 7px;
  width: 80px;
  height: 80px;
  vertical-align: top;
}

.absGreatStyle .boxStyle.active,
.absGreatStyle .boxStyle.active:hover {
  transition: all ease-in 0.3s;
  background: #575757;
}

.absGreatStyle .boxStyle.active small,
.absGreatStyle .boxStyle.active:hover small {
  color: #fff;
}

.absGreatStyle .boxStyle img {
  max-width: 50px;
}

.absGreatStyle .boxStyle small {
  font-weight: bolder;
  text-transform: uppercase;
  display: block;
}

.saveButton {
  position: absolute;
  width: 29px;
  height: 29px;
  right: 15px;
  bottom: 30px;
  overflow: hidden;
  border: 0;
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  outline: none;
  overflow: hidden;
  padding: 0;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, .1);
  cursor: pointer;
  text-align: center;
}

.saveButton svg {
  max-width: 16px;
  vertical-align: middle;
  margin-top: 4px;
}

.absGreatStyle .currentStyle {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 80px;
  height: 80px;
}

.absGreatStyle .expandMode {
  position: absolute;
  left: 80px;
  // width: 380px;
  width: 400px;
  top: 0;
}