* {
  color: #505a5f;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

.main-content.side-content {
  padding-left: 230px;
  margin-bottom: 60px;
}

body {
  background-color: #ecf2f9 !important;
}

.modalHeader h4 {
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 5px;
}

.form-control[readonly]:focus {
  box-shadow: none;
  border-color: #ddd;
}

.sizeVerySMall {
  max-width: 60px !important;
  float: right;
  margin-top: 5px;
}

ol.breadcrumb {
  background: transparent;
  padding: 0px;
  font-size: 12px;
  font-weight: 500;
  color: #505a5f;

  a {
    color: #f16b95;
  }

  a:hover {
    color: #5b5c6b;
  }
}

.main-content-title {
  margin-bottom: 5px;
}

.page-header {
  margin-bottom: 10px;
}

.btn-icon-text {
  background-color: #f15f8d;
  color: #fff;

  svg {
    max-width: 16px;
    margin-right: 10px;
    fill: #fff;
  }
}

.icon-row:hover {
  color: #f16b95;

  .author-name {
    color: #f16b95;
  }

  svg {

    fill: #f16b95;
  }
}

.btn {
  background-color: #f15f8d;
  color: #fff;
  border-color: #f15f8d;
}

.helpSmallError {
  font-size: 10px;
  color: #c83b3b;
}

.sidemenu-logo {
  img {
    width: auto;
    max-width: 100%;
    max-height: 90px;
  }
}

.main-header.side-header {
  padding: 10px 20px 0px 230px;
  margin-bottom: 10px;
  margin-top: 15px;
}

.main-header.side-header .page-header {
  margin-bottom: 0px;

  .breadcrumb {
    margin-bottom: 0rem;
  }
}

.icon-row {
  padding: 0px 20px;
  text-align: right;
  line-height: 50px;
  display: inline-block;
  cursor: pointer;
  position: relative;

  svg {
    fill: #505a5f;
    max-width: 20px;
    width: 20px;
  }

  .avatar-size {
    max-width: 50px;
  }

  .author-name {
    color: #505a5f;
    font-weight: normal;
    font-size: 16px;
    padding-left: 20px;
  }

  .icon-toggle {
    padding-left: 10px;

    svg {
      max-width: 13px;
    }
  }
}

.icon-row.profile-icon {
  padding-right: 25px;
}

.btn-mar-10 {
  margin-right: 10px;
}

.btn.btn-submit {
  width: 130px;
}

.ant-checkbox-disabled .ant-checkbox-inner {
  border: 1px solid #f15f8d !important;
}

.ant-checkbox-disabled .ant-checkbox-inner::after {
  border-color: #fff !important;
  color: #fff !important;
}

.icon-row.profile-icon .icon-toggle {
  position: absolute;
  right: 0;
}

.float-end {
  text-align: right;
}

.btn-large {
  line-height: 35px;
  padding: 0px 15px;
  text-transform: uppercase;
  outline: none !important;
  border: 1px solid #f15f8d;
  margin-top: 0px;
  font-size: 13px;

  svg {
    max-width: 11px;
    fill: #fff;
    position: relative;
    top: -1px;
    margin-right: 5px;
  }

}

.btn-large:hover {
  background-color: transparent;
  color: #f15f8d;
  border-color: #f15f8d;

  svg {
    fill: #f15f8d;
  }
}

.ant-select {
  width: 100%;
}

.btn-primary.focus,
.btn-primary:focus,
.btn-primary:not(:disabled):not(.disabled):active {
  background-color: #f15f8d;
  color: #fff;
  box-shadow: unset;
  border-color: #f15f8d;
}

.btn.btn-large.btn-transparent {
  color: #f15f8d;
  background-color: #fff;

  svg {
    fill: #f15f8d;
  }

}

.btn svg {
  max-width: 16px;
}

.logout-btn svg {
  fill: #f15f8d;
}

.btn.btn-large.btn-transparent:hover {
  background-color: #f15f8d;
  color: #fff;
  border-color: #f15f8d;

  svg {
    fill: #fff;
  }
}

.btn.btn-large.btn-header.btn-transparent {
  margin-left: 15px;
}

.ant-checkbox-checked::after {
  border: 1px solid #f15f8d !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #f15f8d !important;
  border-color: #f15f8d !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus+.ant-checkbox-inner {
  border-color: #f15f8d !important;
}

.btn-submit-bigger {
  padding: 10px 30px !important;
  border-color: #f15f8d !important;
}

.btn.btn-transparent {
  color: #f15f8d;
  background: #fff;
  border: 1px solid #f15f8d !important;

  svg {
    fill: #f15f8d;
  }

  &:hover {
    color: #fff !important;
    background: #f15f8d !important;

    svg {
      fill: #fff;
    }
  }
}

.logout-btn {
  text-align: center;
  padding: 0px;
  background: #ffeef3;
  border-radius: 10px;
  max-width: 140px;
  margin: 0 auto;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 60px;

  .btn {
    background-color: transparent !important;
    color: #f27ca1;
  }

  svg {
    fill: #f15f8d;
  }

  &:hover {
    background: #f15f8d;
    color: #fff !important;

    .btn {

      color: #fff;
    }

    svg {
      fill: #fff;

    }
  }
}

.btn.btn-primary:hover {
  color: #fff !important;
  background-color: #ec5484 !important;
  border-color: #ec5484 !important;
}

button.btn.btn-icon {
  padding-left: 40px !important;
  position: relative;
  top: -2px;
}

.dangerColor {
  color: #f00;
}

button.btn.btn-icon svg {
  position: absolute;
  left: 15px;
  top: 0;
  bottom: 0;
  margin: auto;
}

.ant-select.ft-algin-end {
  text-align: left;
}

.contentGroup .align-middle button.btn.btn-smaller.btn-icon {
  padding-left: 20px !important;
}

.contentGroup .align-middle button.btn.btn-smaller.btn-icon svg {
  left: 5px;
}

.form-control.error {
  border-color: #b91c1c;
}

.Toastify__toast-body {
  color: #fff !important;
}

.contentGroup .align-middle button.btn.btn-smaller.btn-icon:first-child {
  padding-left: 15px !important;
}

.contentGroup .align-middle button.btn.btn-smaller.btn-icon:first-child svg {
  left: 0px;
}

.ant-checkbox-wrapper.ant-checkbox-wrapper-disabled .ant-checkbox-checked::after {
  visibility: visible !important;
}

.ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
  opacity: .5 !important;
}

.contentGroup .align-middle button.btn.btn-smaller.btn-icon:last-child {
  padding-right: 0px;
}

.loaderContainer {
  text-align: center;
  height: 400px;
  vertical-align: middle;
  position: relative;

  img {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    height: 200px;
    top: 0;
    bottom: 0;
  }
}

.logout-btn .btn.btn-icon {
  border: 0px !important;
}

.contentGroup .align-middle button.btn.btn-smaller.btn-icon:first-child {
  padding-left: 15px !important;
}

.contentGroup .align-middle button.btn.btn-smaller.btn-icon:first-child svg {
  left: 0px;
}

#mainSidebarToggle svg {
  max-width: 16px;
  margin-top: 15px;
  float: right;
}

.main-header-left {
  display: none;
}

.dummy-positioning {
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

// The icon specifics
// Variables
$green: #4cc93f;
$icon-base-size: 20px;

// Structure
.success-icon {
  display: inline-block;
  width: 5em;
  height: 5em;
  font-size: $icon-base-size;
  border-radius: 50%;
  border: 4px solid lighten($green, 20%);
  background-color: #fff;
  position: relative;
  overflow: hidden;
  transform-origin: center;
  animation: showSuccess 180ms ease-in-out;
  transform: scale(1);
}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus+.ant-radio-inner {
  border-color: #f15f8d !important;
  box-shadow: none !important;
}

.ant-radio-inner::after {
  background-color: #f15f8d !important;
}

.ant-radio-checked::after {
  border: 1px solid #f15f8d !important;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #f15f8d !important;
  box-shadow: none !important;
}

.ant-radio-inner::after {
  background-color: #f15f8d !important;
  box-shadow: none !important;
}

.has-error {
  border-color: #c11f1f !important;
}

.cotnent-table.error-highlight.row div {
  border-color: #d91010;
  border-top-color: #d91010;
  border-top: 1px solid #d91010;
  margin-top: -1px;
}

.cotnent-table.error-highlight.row {
  background: #ff00001c !important;
}

.headerIconName .author-name {
  line-height: 1;
  display: block;
  text-align: left;
}

.headerIconName {
  display: inline-block;
  max-width: 200px;
  vertical-align: middle;

}

.headerIconName .author-name.author-name-role {
  display: block;
  line-height: 20px;
  text-align: left;
  font-weight: bold;
  font-size: 12px;
}

.headerIconName .author-name.author-name-role span {
  color: #f16b95;
  margin-right: 10px;
}

.contentGroup.nofound .group-header {
  border-left: 4px solid #c42f2f;
}

.subProfile {
  display: none;
}

.subProfile.subProfileActive {
  width: 180px;
  position: absolute;
  right: 0px;
  background: #fffefe;
  z-index: 99;
  -webkit-box-shadow: 3px 10px 7px 0px #f8f5f5bf;
  -moz-box-shadow: 3px 10px 7px 0px #f8f5f5bf;
  box-shadow: 3px 10px 7px 0px #f8f5f5bf;
  border: 1px solid #ddd;
  margin-top: 10px;
  text-align: left;
  list-style: none;
  display: block;
}

.subProfileActive ul {
  list-style: none;
  margin-bottom: 0px;
}

.subProfileActive ul li {
  padding: 5px 20px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
}

.subProfileActive ul li:last-child {
  border-bottom: 0px;
}

.subProfileActive ul li:hover,
.subProfileActive ul li:hover span {
  background: #ffeef3;
  color: #f16b95 !important;
}

.subProfileActive.subProfile ul li svg {
  max-width: 12px;
}

.subProfileActive.subProfile ul li:hover svg {
  fill: #f16b95 !important;
}

.ReactModal__Content.ReactModal__Content--after-open.sucessModal {
  position: absolute;
  border: 1px solid #ccc;
  background: #fff none repeat scroll 0% 0%;
  overflow: auto;
  border-radius: 4px;
  outline: currentcolor none medium;
  padding: 15px;
  width: 400px !important;
  height: 340px;
}

.ReactModal__Content.ReactModal__Content--after-open.sucessModal.without-description {
  height: 310px;
}

.align-title-left h4 {
  text-transform: capitalize;
}

.headerIconProfile {
  display: inline-block;
  position: relative;
  cursor: pointer;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  text-align: center;
  overflow: hidden;
  vertical-align: middle;
}

.overlayEdit {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  opacity: 0;
  transition: all ease-in .3s;
}

.headerIconProfile svg {
  fill: #fff !important;
}

.headerIconProfile:hover .overlayEdit {
  opacity: 1;
}

.fileUploadStyle span {
  background: #f15f8d;
  display: inline-block;
  padding: 0px 20px;
  color: #fff;
}

#file {
  display: none;
}

.fileUploadStyle {
  background-color: #fafafa;
  border: 1px solid #ffefef;
  line-height: 40px;
  cursor: pointer;
}

.fileUploadStyle small {
  padding-left: 20px;
  display: inline-block;
}

.previewArea {
  text-align: left;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  background-color: #fdfdfd;
  padding: 10px 0px;
}

.profileTable img {
  max-width: 30px;
  margin-right: 10px;
}

.hover-message {
  position: relative;

  .messageTag {
    position: absolute;
    left: 0px;
    top: 40px;
    padding: 6px;
    padding-left: 6px;
    background: #000;
    color: #fff !important;
    border-radius: 8px;
    font-size: 11px;
    width: 150px;
    opacity: 0;
    transition: all ease-in 0.3s;
    line-height: 1;
  }

  &:hover .messageTag {
    opacity: 1;
  }
}

.tagAvailStyle {
  text-align: right;
  vertical-align: middle;
  line-height: 40px;
}

button.btn.btn-icon svg.svgSmall {
  max-width: 12px;
}

.headerGroup .align-middle:first-child {
  padding-left: 25px;
}

.contentGroup .group-header .align-middle {
  padding-left: 20px;
}

.contentGroup .group-header .align-middle:last-child {
  text-align: right;
}

.textCap {
  text-transform: capitalize;

}

.ant-select-dropdown {
  text-transform: capitalize;
}

.ant-select-selection-item {
  text-transform: capitalize;
}

.ReactModal__Content.ReactModal__Content--after-open {
  border-radius: 6px !important;
}

.errorPermission {
  background: #ffc9c9;
  padding: 10px;
  border-left: 4px solid red;
}

.btn.btn-smaller.btn-icon.hover-message.btn-delete .messageTag {
  left: auto;
  right: -12px;
  max-width: 90px;
}

.btn.btn-edit.btn-smaller.btn-icon.acessEnable .messageTag {
  display: none;
}

.element-icon.ant-table-cell .hover-message.disabled .messageTag {
  left: 95%;
  text-align: center;
  width: 73px;
  white-space: normal;
  line-height: 1;
  top: 4px !important;
}

.errorMessage {
  font-size: 12px;
  color: #841b1b;
}

.btn.btn-large.btn-header.hover-message .messageTag {
  max-width: 100%;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  padding-right: 40px;
  padding-left: 40px;
}

.headerGroup.row,
.contentGroup.row {
  margin-left: 0px;
  margin-right: 0px;
  width: 100%;
  line-height: 25px;
}

.br-seperator-row {
  padding: 20px;
  background-color: #fff;
  margin-bottom: 15px;
  border-radius: 4px;
  box-shadow: 0 5px 6px 0 #55597c33;
}

.timePickMy .MuiFormControl-root.MuiTextField-root .MuiOutlinedInput-root .MuiInputBase-input {
  padding: 0px;
  max-width: 80%;
}

.timePickMy .MuiFormControl-root.MuiTextField-root .MuiOutlinedInput-notchedOutline {
  border: 0px;
}

.timePickMy .MuiFormControl-root.MuiTextField-root .MuiOutlinedInput-root .MuiButtonBase-root {
  position: absolute;
  right: 0px;
  top: 0;

  padding: 0px;
  bottom: 0;
  margin: auto;
  height: 25px;
  left: 0;
  z-index: 999;
  width: 100%;

}

.timePickMy .MuiFormControl-root.MuiTextField-root .MuiOutlinedInput-root .MuiButtonBase-root .MuiSvgIcon-root {
  float: right;
  right: 10px;
  position: absolute;
}

.ag-theme-alpine .ag-row {
  height: 40px !important;
  min-height: 40px !important;
}

.MuiInputAdornment-root button {
  background: transparent !important;
}

.MuiPickersArrowSwitcher-root button {
  background-color: transparent !important;
}

.MuiButtonBase-root {
  background-color: #f15f8d !important;
}

.MuiButtonBase-root span {
  color: #fff !important;
}

.main-content.side-content .page-header {
  padding: 10px;
  background: #fff;
  border: 0px;
  border-radius: 4px;
  z-index: 9;
  position: relative;
}

.ReactModal__Overlay {
  z-index: 99;
}

.posRel {
  position: relative;
  display: inline-block;
  top: -2px
}

.posRel svg {
  fill: #505a5f;
  margin-left: 2px;
  opacity: .8;
  max-width: 18px;
}

.posRel .helpSmall {
  position: absolute;
  width: auto;
  min-width: 170px;
  border: 0px;
  background: #000;
  color: #fff;
  left: 23px;
  padding: 4px 10px;
  top: -8px;
  opacity: 0;
  transition: all linear 0.3s !important;
  font-size: 12px;
  z-index: 99;
  line-height: 1.3;
  border-radius: 4px;
}

.posRel:hover .helpSmall {
  opacity: 1;
}

.ag-theme-alpine .ag-header,
.ag-theme-alpine .ag-header * {
  height: 40px !important;
  min-height: 40px !important;
  line-height: 40px;
}

.btn-delete-not-Integrated {
  opacity: .5;
  cursor: auto;
  pointer-events: none;
}

// .align-middle .btn.btn-smaller.btn-icon:last-child,.btn-delete-not-Integrated {
//   // opacity: .5;
//   // cursor: auto;
//   // pointer-events: none;
// }
.deleteHighlightName strong {
  color: #c52f2f;
}

// .element-icon.ant-table-cell .btn.btn-edit.btn-smaller:last-child {
//   opacity: .5;
//   pointer-events: none;
//   cursor: auto;
// }

.tagAvailStyle strong {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: #f384a8;
  line-height: 20px;
  float: right;
  border-radius: 50%;
  top: 0;
  bottom: 0;
  margin: auto;
  margin-left: auto;
  position: absolute;
  text-align: center;
  color: #fff;
  margin-left: 5px;
}

.btn.btn-primary.btn-100 img {
  max-width: 25px;
}

.loaderActive.disable {
  opacity: .5;
  cursor: auto;
  pointer-events: none;
}

.btn-loader img {
  max-width: 20px;
  border-radius: 25px;
  position: absolute;
  left: 10px;
  top: 0px;
  bottom: 0px;
  margin: auto;
}

.btn-transparent.btn-icon.btn-loader {
  background: #f15f8d;
  color: #fff;
  opacity: .5;
}

.tagAvailStyle.tagAvailStyle-2 strong {
  width: 25px;
  height: 25px;
  line-height: 25px;
}

.tagAvailStyle.tagAvailStyle-3 strong {
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 12px;
}

.tagAvailStyle.tagAvailStyle-4 strong,
.tagAvailStyle.tagAvailStyle-5 strong,
.tagAvailStyle.tagAvailStyle-6 strong,
.tagAvailStyle.tagAvailStyle-7 strong {
  width: auto;
  padding: 0px 10px;
  border-radius: 5px;
  line-height: 30px;
  height: 30px;
}

.ReactModal__Content.ReactModal__Content--after-open {
  border-radius: 6px !important;
  position: absolute;
  inset: 50% auto auto 50%;
  border: 1px solid #ccc;
  background: #fff none repeat scroll 0% 0%;
  overflow: auto;
  border-radius: 4px;
  outline: currentcolor none medium;
  padding: 20px;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  width: 400px;
  max-height: 90%;
}

.listNOFound {
  text-align: left;
}

.ant-select-dropdown {
  z-index: 999;
}

.fileUploadStyle small {
  padding-left: 20px;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: calc(100% - 115px);
  vertical-align: middle;
}

.ReactModal__Content.ReactModal__Content--after-open.sucessModal.with-errorMessage {
  text-align: left;
}

.ReactModal__Content.ReactModal__Content--after-open.sucessModal.with-errorMessage h4 {
  color: #d52424;
  border-bottom: 1px solid #ddd;
  padding-top: 0px;
  margin-top: 5px;
  padding-bottom: 10px;
}

.ReactModal__Content.ReactModal__Content--after-open.sucessModal.with-errorMessage .empValidation {
  padding: 8px 15px;
  border: 1px solid #ddd;
  border-top: 0px;
  font-size: 12px;
  line-height: 1.2;
}

// .ReactModal__Content.ReactModal__Content--after-open.sucessModal.with-errorMessage .empValidation:last-child{
//   border-bottom: 0px;
// }
.empValidation strong {
  color: #a44343;
  font-weight: normal;
}

.ReactModal__Content.ReactModal__Content--after-open.sucessModal.with-errorMessage .btn.btn-close {
  width: 100%;
  margin-top: 10px;
}

.someHieght {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.timezone-map polygon[data-selected="true"] {
  fill: #5A5A5A;
  stroke: #5A5A5A;
}

.timezone-map polygon:hover {
  fill: #5A5A5A;
  stroke: #5A5A5A;
  cursor: pointer;
}

.filter-box .quick-link {
  height: 30px;
}

.filter-box .quick-link span {
  padding: 0px 10px;
  width: auto;
  display: inline-block;
  height: 27px;
  line-height: 27px;
}

.filter-box .quick-link span.active,
.filter-box .quick-link span:hover {
  color: #fff;
  background-color: #e7457b;
  border-color: #e7457b;
}

.hover-text {
  display: none;
}

.timezone-map polygon {
  fill: #BBB;
  // stroke: #BBB;
}

.timezone-map polygon.active {
  fill: #5A5A5A;
}

.main-body.leftmenu.ReactModal__Body--open {
  overflow: hidden;
}

.ant-modal-confirm-body svg {
  fill: #f16b95 !important;
}

.ant-modal-confirm-btns .ant-btn.ant-btn-primary {
  background-color: #f16b95 !important;
  border: 0px !important;
  color: #fff !important;
}

.ant-modal-confirm-btns .ant-btn.ant-btn-primary span {
  color: #fff !important;
}

.validationResult strong {
  color: #f16b95
}

.btn-primary:disabled {
  color: #fff;
  background-color: #f15f8d;
  border-color: #f15f8d;
}

.ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon svg {
  width: 23px;
  height: 23px;
  padding: 5px;
}

// .ant-tooltip.ant-slider-tooltip.ant-tooltip-placement-bottom .ant-tooltip-inner {
//   background: #fff;
//   color: #000;
//   padding: 0px 10px;
//   font-size: 12px;
//   height: 20px;
//   min-height: 20px;
// }

// .ant-tooltip.ant-slider-tooltip.ant-tooltip-placement-bottom .ant-tooltip-inner .ant-tooltip-arrow-content::before {
//   background: #fff;
// }
.ant-slider-track {
  background-color: #f15f8d;
}

.field-row.field-row-box .ant-slider-mark {
  font-size: 10px;
  margin-left: 5px;
}

.field-row.field-row-box {
  padding: 10px;
  background: #f8f8f8;
}

.field-row.field-row-box .ant-slider-track {
  background: #f15f8d !important;
}

.main-sidebar-body .nav {
  width: 100%;
}