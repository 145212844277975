.loginComponentMainDiv {
    height: 100vh;
    position: relative;
    overflow: hidden;

    .firstRowDiv {
        height: 68vh;
        background: #f7f4da;
        width: 100vw;
        position: relative;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
    }

    .secondRowDiv {
        height: 32vh;
        background: #a7c6a7;
        width: 100vw;
        position: relative;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
    }
    .logoDiv {
  position: absolute;
  z-index: 99;
  top: 60px;
}.logoDiv {
    position: absolute;
    z-index: 99;
    top: 60px;
  }

    .loginFormWithLogoDiv {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        margin: 20px 10px;

        @media screen and (min-width: 1200px) {
            min-width: 1020px;
        }

        .logoDiv {
            text-align: center;
        }
        .leftformDiv {
            width: calc(100% - 400px);
            float: left;
          }
          .top-graphic{

             img {
            max-width: 90%;
          }
        }
        .loginFormMainDiv {
            margin: 2rem 0;
            background: #FFF;
            border-radius: 0.5rem;
            padding: 2.5rem;
            width: 400px;
            right: 0;
            float: right;

            .loginText {
                color: #394584;
                font-size: 1.65rem;
                font-weight: 600;
            }

            .loginForm {
                .eachElement {
                    margin: 1rem 0;
                    position: relative;

                    img{
                        position: absolute;
                        top: 2.5rem;
                        cursor: pointer;
                        right: 0.5rem;
                        width: 25px;
                    }

                    label {
                        color: #394584;
                        display: block;
                        font-size: 1rem;
                    }

                    input {
                        display: block;
                        width: 100%;
                        height: calc(1.5em + 1.25rem + 2px);
                        padding: 0.625rem 0.75rem;
                        font-size: 0.875rem;
                        font-weight: 400;
                        line-height: 1.5;
                        color: #8898aa;
                        background-color: #fff;
                        border: 1px solid #cad1d7;
                        border-radius: 0.375rem;
                        box-shadow: none;
                        outline: none;
                        transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);

                        &:focus {
                            outline: none;
                            color: #8898aa;
                            background-color: #fff;
                            border: 1px solid #cad1d7;
                        }
                    }
                }
            }

            .forgetPasswordText{
                color: #394584;
                font-size: 1rem;
                cursor: pointer;

                &:hover{
                    text-decoration: underline
                }
            }

            .loginFormButton{
                margin: 2rem auto 1rem;
                display: block;
                padding: 0.75rem;
                border: none;
                outline: none;
                border-radius: 0.35rem;
                text-align: center;
                background: #f17398;
                min-width: 100%;
                color: #FFFFFF;
            }
        }
    }
}
