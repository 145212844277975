.unchecked svg {
    max-width: 12px;
    fill: #de5555;
    opacity: .8;
  }
  .checked svg {
    max-width: 12px;
    fill: #de5555;
    opacity: .8;
  } 
  .checked {
      svg {
      fill: #4b9953;
      max-width: 14px;
    }
  }