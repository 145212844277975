.align-with-title {
  display: inline-block;
}

.headerGroup {
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 50px;
}

.filter-parent {
  cursor: pointer;

  svg {
    max-width: 18px;
    position: relative;
    top: -2px;
    margin-left: 10px;
  }
}

.filter-icon.active svg {
  max-width: 10px;
}

.modalBody {
  position: relative;
}

.sub-stats {
  line-height: 1;
}

.contentGroup .align-middle button.btn.btn-smaller.btn-icon svg.hexagonal-plus {
  max-width: 16px;
}

.contentGroup.contentGroupSubCat.row {
  display: none;
}

.contentGroup.active .contentGroup.contentGroupSubCat {
  display: block;
  margin: 0px;
  border-radius: 0px;
  max-width: 100%;
  padding: 10px;
}

.contentGroup.active .contentGroup.contentGroupSubCat .group-header.group-header-subCat {
  background: #f9f9f9;
  padding: 0px;
  border: 1px solid #ddd;
}

.contentGroup.active .contentGroup.contentGroupSubCat.active .group-header.group-header-subCat {
  border-bottom: 0px;
}

.contentGroup.contentGroupSubCat.row:hover {
  box-shadow: unset;
}

.headerGroup {
  display: table;
  width: calc(100% + 15px);
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  height: 100%;
  box-shadow: 0px 0px 5px 0px #fdfdfdbf;
  margin-bottom: 10px;
  display: table;
  padding: 5px 0px;

  .align-middle {
    display: table-cell;
  }
}

.contentGroup {
  margin-right: 0px;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  height: 100%;
  box-shadow: 0px 0px 5px 0px #fdfdfdbf;
  margin-bottom: 10px;
  display: table;
  width: calc(100% + 15px);

  .sub-stats {
    strong {
      color: #f15f8d;
    }
  }

  .align-middle {
    display: table-cell;
  }

  .align-title-left {
    display: table-cell;
    vertical-align: middle;

    h4 {
      margin-bottom: 0px;
      font-size: 16px;
      color: #212337;
    }
  }

  .group-header {
    display: table;
    width: 100%;
  }

  .group-content {
    height: 0px;
    transition: height ease-in 0.3s;
    overflow: hidden;
  }

  .align-with-title {
    display: table-cell;
    vertical-align: middle;
  }
}

.accordion-icon {
  display: table-cell;
  width: 20px;
  vertical-align: middle;
  cursor: pointer;

  svg {
    max-width: 12px;
    fill: #596367;
  }

  &:hover {
    svg {
      fill: #f15f8d;
    }
  }

}

.contentGroup:hover {
  box-shadow: 0px 0px 5px 0px #cabdbd7a;
}

.btn.btn-smaller {
  background-color: transparent !important;
  color: #596367;
  font-size: 14px;
  line-height: 33px;
  border: 0px !important;
  box-shadow: unset !important;

  &:hover {
    color: #f15f8d;

    svg {
      fill: #f15f8d
    }
  }
}

.btn.btn-smaller svg {
  max-width: 14px;
  fill: #596367;
  position: relative;
  top: -1px;
}

.contentGroup .group-content.active .row {
  margin: 0px;
}


.contentGroup .group-content.active {
  height: auto;
  padding: 15px;
  border-top: 1px solid #ddd;
}

.subHeaderEmptTable.row,
.subContentTable.row {
  margin-bottom: 8px !important;
  width: 100%;
  display: table;
  border-radius: 4px;
}

.subHeaderEmptTable.row {
  margin-bottom: 0px !important;
}

.subContentTable.row .col-lg-3,
.subHeaderEmptTable.row .col-lg-3 {
  display: table-cell;
  line-height: 30px;
}

.subHeaderEmptTable.row {
  line-height: 30px;

  .col-lg-3 {
    line-height: 40px;
  }
}

.contentGroup .group-content .subContentTable {
  border: 1px solid #ddd;
}

// .contentGroup .group-content .subContentTable:nth-child(2n+1) {
//   background-color: #f8f8f8;
// }
.subContentTable.row .btn.btn-smaller {
  padding-top: 0px;
  padding-bottom: 0px;
}

.btn.btn-smaller span {
  line-height: 14px;
  display: inline-block;
  padding-left: 5px;
}

.btn.btn-smaller:hover span {
  color: #f15f8d;
}

.subContentTable .btn.btn-smaller:first-child {
  padding-left: 0px;
}

.subHeaderEmptTable.row .filter-icon svg {
  max-width: 15px;
  position: relative;
  top: -2px;
  margin-left: 2px;
}

.contentGroup .group-header {
  padding: 4px 0px;
}

.contentGroup.active .group-header {
  background: #ffeef3;
}

.hoverDisplayEdit .btn {
  margin-left: 10px;
  opacity: 0;
  transition: all ease-in 0.3s;
}

.hoverDisplayEdit:hover .btn {
  opacity: 1;
}

.dangerDeleteZone {
  height: 180px;
  text-align: center;

  .deleteZoneHeader {
    svg {
      fill: #c52f2f;
      max-width: 60px;
    }

    h2 {
      margin: 15px 0px;
      text-transform: uppercase;
    }
  }

  .deleteZoneAction {
    .btn {
      width: 120px;
      line-height: 30px;
      margin-top: 20px;
      border: 1px solid #f15f8d !important;
    }

    .btn:first-child {
      margin-right: 10px;
    }

    .btn.btn-transparent {
      background-color: transparent;
      color: #f15f8d;

      &:hover {
        background-color: #f15f8d !important;
        color: #fff !important;
      }
    }

    .btn.btn-primary {
      &:hover {
        background-color: transparent !important;
        color: #f15f8d !important;
      }
    }

  }
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #a9a9a9;
  border-right-width: 1px;
}

.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 40px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 40px;
  border-radius: 4px !important;
}

.loaderContainer.loaderContainerSmall {
  height: 200px;
}

.headerGroup .align-middle.userManagementLast.col-lg-5,
.contentGroup .align-middle.userManagementLast.col-lg-5 {
  display: inline;
}

.headerGroup .align-middle.userManagementLast.col-lg-5,
.contentGroup .align-middle.userManagementLast.col-lg-5 {
  float: right;
  min-width: 505px !important;
  max-width: 505px !important;
  text-align: center;
}

.timePickMy .MuiFormControl-root .MuiOutlinedInput-root {
  display: block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

/* CSS for text truncation */
.truncate-text {
  max-width: 200px;
  /* Adjust this width to your preferred maximum width */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fileUploadStyle[disabled] {
  opacity: .5;
  cursor: not-allowed;
}