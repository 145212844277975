.align-with-title {
    display: inline-block;
}
.headerCategory {
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 50px;
  }
  .filter-parent {
    cursor: pointer;
    svg {
      max-width: 18px;
      position: relative;
      top: -2px;
      margin-left: 10px;
    }
  }
  .filter-icon.active svg {
    max-width: 10px;
  }
   
  .headerCategory{
    display: table;
    width: 100%;
    margin-right: 0px;
    border: 1px solid #e1e1e1;
    border-radius: 4px;
    height: 100%;
    box-shadow: 0px 0px 5px 0px #fdfdfdbf;
    margin-bottom: 10px;
    display: table;
    padding: 5px 0px;
    .align-middle{
        display: table-cell;
    }  
  } 
  .contentCategory{
    margin-right: 0px;
    border: 1px solid #e1e1e1;
    border-radius: 4px;
    height: 100%;
    box-shadow: 0px 0px 5px 0px #fdfdfdbf;
    margin-bottom: 10px;
    display: table;
    width: 100%;
    .sub-stats{
      line-height: 1;
      strong {
        color: #f15f8d;
      }
    }
    
    .align-middle{
        display: table-cell;
    }
  .align-title-left {
    display: table-cell;
    vertical-align: middle;
    h4 {
        margin-bottom: 0px;
        font-size: 16px;
        color: #212337;
        }
    }
    .Category-header {
        display: table;
        width: 100%;
      }
    .Category-content{
        height: 0px;
        transition: height ease-in 0.3s;
        overflow: hidden;
    }
    .align-with-title{
        display: table-cell;
        vertical-align: middle;
    }
}
  .accordion-icon {
    display: table-cell;
    width: 20px;
    vertical-align: middle;
    cursor: pointer;
    svg {
        max-width: 12px;
        fill: #596367;
    }
    &:hover {
        svg{
            fill: #f15f8d;
        }
    }

  }
  .contentCategory:hover {
    box-shadow: 0px 0px 5px 0px #cabdbd7a;
  }
  .btn.btn-smaller {
    background-color: transparent !important;
    color: #596367;
    font-size: 14px;
    line-height: 33px;
    border: 0px !important;
    box-shadow: unset !important;
    &:hover{
        color: #f15f8d;
        svg{
            fill:#f15f8d
        }
    }
  }
  .btn.btn-smaller svg {
    max-width: 14px;
    fill: #596367;
    position: relative;
    top: -1px;
  }
  .contentCategory .Category-content.active .row {
    margin: 0px;
  }

  
  .contentCategory .Category-content.active {
    height: auto;
    padding: 15px;
    border-top: 1px solid #ddd;
  } 
  .subHeaderEmptTable.row,.subContentTable.row {
    margin-bottom: 8px !important;
    width: 100%;
    display: table;
    border-radius: 4px;
  }
  .subHeaderEmptTable.row{
    margin-bottom: 0px !important;
  }
  .subContentTable.row .col-lg-3,
  .subHeaderEmptTable.row .col-lg-3 {
    display: table-cell;
    line-height: 30px;
  }
  .subHeaderEmptTable.row {
    line-height: 30px;
        .col-lg-3 {
        line-height: 40px;
    }
  }
  .contentCategory .Category-content .subContentTable {
    border: 1px solid #ddd;
  }
  // .contentCategory .Category-content .subContentTable:nth-child(2n+1) {
  //   background-color: #f8f8f8;
  // }
  .subContentTable.row .btn.btn-smaller {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .btn.btn-smaller span {
    line-height: 14px;
    display: inline-block;
    padding-left: 5px;
  }
  .btn.btn-smaller:hover span {
    color: #f15f8d;
  }
  .subContentTable .btn.btn-smaller:first-child {
    padding-left: 0px;
  }
  .subHeaderEmptTable.row .filter-icon svg {
    max-width: 15px;
    position: relative;
    top: -2px;
    margin-left: 2px;
  }

  .contentCategory .Category-header {
    padding: 4px 0px;
  }
  .contentCategory.active .Category-header {
    background: #ffeef3;
  }

  .hoverDisplayEdit .btn {
    margin-left: 10px;
    opacity: 0;
    transition: all ease-in 0.3s;
  }
  .hoverDisplayEdit:hover .btn{
    opacity: 1;
  } 
  .dangerDeleteZone {
    height: 180px;
    text-align: center;
    .deleteZoneHeader {
      svg{
        fill:#c52f2f;
        max-width: 60px;
      }
      h2 {
        margin: 15px 0px;
        text-transform: uppercase;
      }
    }
    .deleteZoneAction{ 
      .btn {
        width: 120px;
        line-height: 30px;
        margin-top: 20px;
        border: 1px solid #f15f8d !important;
      }
      .btn:first-child{
        margin-right:10px;
      }
      .btn.btn-transparent {
        background-color: transparent;
        color: #f15f8d;
        &:hover{
          background-color: #f15f8d !important;
          color: #fff  !important;
        }
      }
      .btn.btn-primary{
        &:hover{
          background-color: transparent !important;
        color: #f15f8d !important;
        }
      }
      
    }
  }

  .headerCategory .align-middle.siteManagementlast {
    max-width: 315px;
    min-width: 315px;
    float: right;
    text-align: center;
  }
  .btn.btn-edit.btn-smaller.btn-rightNOspace {
    padding-right: 0px !important;
  }