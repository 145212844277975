.statusHighlight{
    position:relative;
    color:#68c44e;
    span {
        width: 6px;
        height: 6px;
        background: #68c44e;
        position: relative;
        display: inline-block;
        margin-right: 5px;
        border-radius: 50%;
        top: -2px;
    }

}
.element-icon.ant-table-cell.enable-btn .btn.btn-edit.btn-smaller {
    opacity: 1;
    cursor: pointer;
    pointer-events: all;
  }
  
.statusHighlight.fail{
    color:#f42e2e;
    span{
        background: #f42e2e;
    }
}

.ReactModal__Content.ReactModal__Content--after-open.modalremove {
    padding: 30px;
    text-align: center;
}
.ReactModal__Content.ReactModal__Content--after-open.modalremove .btn.btn-transparent {
    margin-left: 10px;
  }
  .ReactModal__Content.ReactModal__Content--after-open.modalremove h2 {
    font-size: 24px;
  } 
  .ReactModal__Content.ReactModal__Content--after-open.modalremove p {
    color: #851717;
    font-size: 14px;
  }