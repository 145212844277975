.bodyAreaCol.col-lg-4 {
    padding: 15px;
    border: 1px solid #ddd;
    text-transform: capitalize;
}

.headerAreaCol {
    padding: 10px 20px;
    border: 1px solid #ddd;
    border-bottom: 0px;
}

.headerAreaCol h4 {
    margin-bottom: 0px;
    font-size: 16px;
}

.headerAreaCol.headerAreaColCOlor2 {
    background: #c2ecd5;
}

.headerAreaCol.headerAreaColCOlor2 h4 {
    color: #175922;
}


.bodyAreaCol.bodyAreaCol2 {
    background: #c2ecd5;
    color: #175922;
    border-top: 1px solid #93bf9b;
}

.bodyAreaCol.bodyAreaCol2 * {
    color: #175922;
}

.headerAreaCol.disabled {
    background: #dfbaba;
    opacity: .9;
}

.headerAreaCol.disabled h4 {
    color: #7b1a1a;
}

.bodyAreaCol.disabled,
.bodyAreaCol.disabled * {
    background: #dfbaba;
    color: #7b1a1a;
    opacity: .9;
}

// .area2.selected * {
//     color: #fff;
// }