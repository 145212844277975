.box-space-bottom{
    margin-bottom: 15px;
    padding-right: 0px;
}
.boxDashboardStats {
    padding: 25px;
    border-radius: 25px;
    height: 100%;
    -webkit-box-shadow: 0px 0px 5px 0px #fdfdfdbf;
    -moz-box-shadow: 0px 0px 5px 0px #fdfdfdbf;
    box-shadow: 0px 0px 5px 0px #fdfdfdbf;
    background: #fff;
    transition: all ease-in 0.3s;
    
    &:hover {
      box-shadow: 0px 0px 5px 0px #cabdbd7a;
       }
    .headerStats {
        text-align: center;
        margin-bottom: 40px;
        cursor: pointer;
        
        .biggerFont {
            font-size: 90px;
            line-height: 1.3;
        }
        .smallerFont {
            text-transform: uppercase;
            font-weight: bold;
            color: #929699;
            font-size: 18px;
            letter-spacing: 1px;
          } 
      }
      .contentAreaStats{
        font-size: 14px;
        color: #929699;
        .rowStats {
            padding-bottom: 5px;
            width: 100%;
            .subCount {
                //font-weight: bold;
                float: right;
                font-size: 12px;
                color: #414c51;
            }


        }
      }
}
.box-stat-rows .col-lg-4:nth-of-type(3n) {
    padding-right: 15px !important;
  }
  .boxDashboardStats.boxDashboardStatsDevice .contentAreaStats .rowStats {
    text-align: right;
  }
  .boxDashboardStats .contentAreaStats .rowStats span {
    display: inline-block;
    float: none;
  } 
  .boxDashboardStats .contentAreaStats .rowStats .titleLeft {
    display: inline-block;
    min-width: 30%;
    text-align: left;
    float: left;
  }
  .boxDashboardStats.boxDashboardStatsDevice .contentAreaStats .rowStats .subCount {
    float: none;
    display: inline-block;
    min-width: 70px;
    text-align: center;


  } 
  .boxDashboardStats.boxDashboardStatsDevice .contentAreaStats .rowStats .subCount {
    min-width: 20%;
    width: 20%;
    float: none;
    display: inline-block;
    text-align: center;
  }
  .boxDashboardStats.boxDashboardStatsDevice .contentAreaStats .rowStats:first-child {
    line-height: 1;
    border-bottom: 1px solid #e2e2e2;
    vertical-align: middle;
    margin-bottom: 10px;
  }