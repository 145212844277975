.form-field .leftDiv50 {
  width: 48%;
  float: left;
}
.form-field .leftDiv50:last-child {
  margin-left: 4%;
}
.listHead {
	float: left;
	width: 100%;
	margin: 10px 0px;
	background: #f9f4f4;
	padding: 5px 20px;
	border: 1px solid #d5d0d0;
	font-weight: bold;
	border-radius: 4px;
}
.contentGroup .align-title-left .inline {
  display: inline-block;
  margin-right: 10px;
}
.headerGroup.row, .contentGroup.row {
  height: auto;
}
.headerAreaTeam .btn.btn-close {
  position: absolute;
  right: 15px;
  top: 15px;
}
.headerAreaTeam {
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
}
.footerAreaTeam {
  margin-top: 10px;
  border-top: 1px solid #ddd;
  padding-top: 10px;
  text-align: right;
}
.bodyAreaTeam .contentGroup {
  margin-bottom: 5px;
}
.bodyAreaTeam .contentGroup .group-content.group-sub-content.active .listEmpMap h4 .ant-checkbox-wrapper {
  line-height: 1;
  margin-top: -1px;
}
.bodyAreaTeam .contentGroup .group-content.active {
  height: auto;
  padding: 10px 0px;
  border-top: 1px solid #ddd;
}
.bodyAreaTeam .contentGroup .group-content.group-sub-content.active .listEmpMap h4 {
  font-size: 13px;
  margin-left: 0;
  margin-bottom: 5px;
  border: 1px solid #ddd;
  padding: 3px 75px;
}
.bodyAreaTeam .contentGroup.active .contentGroup.contentGroupSubCat .align-middle.col-lg-2 {
  padding-right: 0px;
}
.bodyAreaTeam .ant-select.form-control.ant-select-single.ant-select-show-arrow {
  border-radius: 4px;
  height: 38px;
}
.listSelectedEMp {
  height: 100%;
  border-radius: 4px;
  padding: 20px;
}
.listSelectedEMp .employeee {
  margin-bottom: 5px;
  width: auto;
  display: inline-block;
  margin-right: 10px;
  border: 1px solid #ddd;
  padding: 0px 5px;
  font-size: 12px;
  border-radius: 6px;
}
.employeee span {
   float: left;
}
.listSelectedEMp .removeEmp {
  margin-left: 6px;
  width: 12px;
  height: 12px;
  background-color: #f15f8d;
  border-radius: 50%;
  cursor: pointer;
  text-align: center;
  line-height: 1;
  color: #fff;
  font-size: 10px;
  top: -1px;
  position: relative;
  display: inline-block;
}
.bodyAreaTeam .contentGroup .group-content.active.group-sub-content-only-parent {
  margin-left: 20px;
  margin-right: 20px;
}
.error {
  color: red;
  background: #ddd;
  padding: 0px 9px;
}
