.listTable .locationList {
    display: block;
    height: 40px;
}

.listTable {
    display: inline-block;
    text-align: left;
    border-left: 1px solid #ddd;
    min-width: 85px;
    float: left;
    border-bottom: 1px solid #ddd;
}

.locationRepeat {
    float: left;
    width: 85px;
}

.listTable .locationList {
    display: block;
    height: 60px;
}

.nestedDIfficultTable {
    flex: 1;
    display: flex;
    overflow: hidden;
}


.listTable .catName {
    height: 40px;
    // background: #ddd;
    line-height: 40px;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    // border-right: 1px solid #aea3a3;
    text-align: center;
    word-wrap: break-word;
}

.locationRepeat {
    float: left;
    width: 84px;
    padding: 10px;
    font-size: 12px;
    height: 70px;
    // border-bottom: 1px solid #ddd;
    font-weight: 600;
    word-wrap: break-word;
    line-height: 15px;
}

.listTable .locationRepeat:only-child {
    border: 0px;
}

.listTable .locationRepeat {
    border-right: 1px solid #ddd;
}

.listTable .locationList:last-child {
    border-right: 0px;
}

.nestedDIfficultTable.nestedDIfficultTableSmall .listTable {
    height: 35px;
}

.listTable .locationList .locationRepeat:last-child {
    border-right: 0px;
}

.nestedDIfficultTable.nestedDIfficultTableSmall .listTable .locationList {
    height: 35px;
}

.nestedDIfficultTable .listTable:last-child {
    border-right: 1px solid #ddd;
}

.nestedDIfficultTable.nestedDIfficultTableSmall .locationRepeat {
    height: 35px;
    padding: 5px;
}

.nestedDIfficultTable.nestedDIfficultTableSmall .locationRepeat .form-control {
    height: 25px;
    padding: 3px 10px;
    font-size: 10px;
}

.nestedDIfficultTable.nestedDIfficultTableHeader .locationList .locationRepeat:first-child {
    border-left: 0px;
}

.nestedDIfficultTable.nestedDIfficultTableHeader .listTable .locationList {
    height: 70px;
}

.locationList.locationListDate .locationRepeat,
.locationList.locationListHour .locationRepeat {
    line-height: 25px;
}

.parentScroll {
    overflow-y: scroll;
}

.listTable.listTable0,
.listTable.listTable2,
.listTable.listTable4,
.listTable.listTable6,
.listTable.listTable8,
.listTable.listTable10,
.listTable.listTable12,
.listTable.listTable14,
.listTable.listTable16,
.listTable.listTable18,
.listTable.listTable20,
.listTable.listTable22 {
    background: #f5f5f5;
}

.listTable.listTable1,
.listTable.listTable3,
.listTable.listTable5,
.listTable.listTable7,
.listTable.listTable9,
.listTable.listTable11,
.listTable.listTable13,
.listTable.listTable15,
.listTable.listTable17,
.listTable.listTable19,
.listTable.listTable21 .displayINlineLEftTable .nestedDIfficultTable.nestedDIfficultTableHeader {
    background: #fff;
}

.react-datepicker__input-container input {
    width: 100px;
    border: 1px solid #ddd;
    margin-right: 10px;
    height: 32px;
    border-radius: 5px;
    padding: 5px 15px;
}

.btn.BtnImport {
    padding: 10px 20px;
    font-size: 12px;
    line-height: 1;
}

.displayINlineLEftTable {
    float: left;
}

.ModalHighLight {
    position: fixed !important;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    top: 0;
    background: rgba(0, 0, 0, 0.1);
    z-index: 99;
    text-align: center;
}

.ModalHighLight img {
    position: absolute;
    margin: auto;
    background: #fff;
    padding: 10px;
    border-radius: 50%;
    height: 200px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.locationList.changesinLocation input {
    background: #c9f0da !important;
    color: #325c14;
}

.nestedDIfficultTable.nestedDIfficultTableHeader {
    // position: fixed;
    height: 110px;
    background-color: #fff;
    z-index: 9999;
}

.nestedDIfficultTable.nestedDIfficultTableHeader .rightDiv {
    flex: 1 1;
    display: flex;
    overflow: hidden;
}

.nestedDIfficultTable.nestedDIfficultTableHeader {
    border-bottom: 1px solid #ddd;
}

.displayINlineLEftTableFull {
    position: sticky;
    top: 0px;
}

.nestedDIfficultTable.nestedDIfficultTableHeader .leftDiv {
    z-index: 9999;
    position: relative;
    display: flex;
    background: #fff;
}

.displayINlineLEftTableFull .leftDiv div,
.displayINlineLEftTable div {
    border-right: 0px !important;
}

.importBY .btn.btn-select.btn-primary {
    border-radius: 0px;
    width: 50%;
}

.importBY .btn.btn-select.btn-primary.firstButton {
    border-radius: 10px 0px 0px 10px;
    border-right: 1px solid #dddd;
}

.importBY .btn.btn-select.btn-primary.SecondButton {
    border-radius: 0px 10px 10px 0px;
}

.btn.btn-select.btn-primary.btn-non-selected {
    background: #fafafa;
    border-color: #ddd;
    color: #000;
}

.field-row.field-row-modalDate .react-datepicker__input-container input {
    width: 100%;
    height: 35px;
}

.field-row.field-row-modalDate .react-datepicker-wrapper {
    width: 100%;
}

.field-row.field-row-modalDate .react-datepicker-popper {
    top: -40px !important;
    transform: unset !important;
    width: 100px !important;
}

.bodyAreaCol .react-datepicker-wrapper {
    width: 100% !important;
}

.holidayDate.form-control {
    width: 100% !important;
    height: 30px;
}

.holidayName.form-control {
    height: 30px;
}

.bodyAreaCol.col-lg-2 .btn-add {
    text-align: right;
    padding: 10px 20px;
    font-size: 14px;
    border: 0px;
    background: #f2739b;
    color: #fff;
    border-radius: 4px;
    margin-left: 10px;
}

.bodyAreaCol.col-lg-5,
.bodyAreaCol.col-lg-2 {
    padding: 10px 15px;
    border: 1px solid #ddd;
    text-transform: capitalize;
}

.bodyAreaCol.col-lg-2 .btn-add {
    text-align: right;
    padding: 7px 12px;
    font-size: 12px;
    border: 0px;
    background: #f2739b;
    color: #fff;
    border-radius: 4px;
    margin-left: 10px;
}

.weeklist .weekdays {
    display: inline-block;
}

.btn-add.btn-abs-small {
    position: absolute;
    right: 10px;
    width: 20px;
    height: 18px;
    line-height: 10px;
    padding: 0px !important;
    text-align: center !important;
    margin-top: 8px;
    font-size: 17px !important;
}