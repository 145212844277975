.dropdown-container .option img {
  width: 50px;
  margin-right: 10px;
}

.dropdown-container .options-container .option {
  padding: 5px 0px;
  cursor: pointer;
}

.dropdown-container .options-container .option:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.dropdown-container .options-container {
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
  outline: none;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  left: 0;
  right: 0;
  margin: auto;
  position: absolute;
  z-index: 9;
}

.dropdown-container {
  position: relative;
}

.dropdown-container .selected-option Img {
  max-width: 50px;
}

.dropdown-container .selected-option {
  padding: 10px;
  border: 2px solid #ddd;
}

.dropdown-container .options-container .option.optionActive {
  background-color: #e6f4ff;
  color: #000
}

.pageVersionList .pageVersionCheck {
  display: inline-block;
  max-width: 75px;
  margin-right: 10px;
  line-height: 75px;
  font-weight: bolder;
  width: 75px;
  text-align: center;
  border: 1px solid #ddd;
  cursor: pointer;
  position: relative;
}


.pageVersionList .pageVersionCheck img {
  max-width: 75px;
  position: absolute;
  z-index: -9;
  top: 0;
  object-fit: cover;
  object-position: left center;
  width: 75px;
  height: 75px;
  left: 0px;
  opacity: .5;
}

.pageVersionList .pageVersionCheck.active {
  background: rgba(237, 105, 146, 1);
  color: #fff;
}

.pageVersionList .pageVersionCheck.active div {
  color: #fff;
}

.headerAreaTeam.headerAreaTeam2 h4 {
  font-size: 16px;
  margin-bottom: 16px;
  margin-top: 5px;
}

.errorSmall {
  background: #f1cccc;
  border-left: 4px solid #8f2d2d;
  padding: 0px 11px;
  font-size: 11px;
  margin-top: 5px;
}

.pageVersionList {
  padding: 4px;
  border: 1px solid #ddd;
}

.contentGroup .btn.btn-smaller {
  font-size: 13px;
  line-height: 13px;
  border: 0px !important;
  box-shadow: unset !important;
}

.contentGroup .accordion-icon svg {
  max-width: 8px;

}