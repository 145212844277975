.ReactModal__Content.ReactModal__Content--after-open.create-form-user {
    position: absolute;
    border: 1px solid #ccc;
    background: #fff none repeat scroll 0% 0%;
    overflow: auto;
    border-radius: 4px;
    outline: currentcolor none medium;
    padding: 15px;
    width: 80% !important;
    height: 550px;
    height: auto;
  }
  .ant-steps-item-title {
    font-size: 13px;
  }
  .ant-steps-item-icon {
    width: 25px;
    height: 25px;
    line-height: 25px;
 }
  .ReactModal__Content.ReactModal__Content--after-open.create-form-user .modalHeader {
    margin-bottom: 15px;
  }
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background: #f15f8d;
    border-color: #f15f8d;
  }
  .ReactModal__Content.ReactModal__Content--after-open.create-form-user .ant-steps-item-title {
    font-size: 13px;
    line-height: 25px;
  }
  .ReactModal__Content.ReactModal__Content--after-open.create-form-user .ant-steps-item-title::after {
    top: 13px;
    }
  .ant-btn.ant-btn-primary.btn.btn-primary {
    background: #f15f8d;
    color: #fff !important;
    border-color: #f15f8d !important;
    min-width: 150px;
    font-size: 12px;
    line-height:24px;
    height:auto
  }
  .ant-btn.ant-btn-default.btn.btn-primary {
    min-width: 150px;
    font-size: 13px;
    line-height: 24px;
    height: auto;
  }
  .ant-btn.ant-btn-default.btn.btn-primary:hover,  .ant-btn.ant-btn-default.btn.btn-primary:hover span{
    color: #fff;
  }
  .ant-steps-item-finish .ant-steps-item-icon {
    background-color: #fff;
    border-color: #f15f8d;
    position: relative;
  }
  
  .ant-btn.ant-btn-primary.btn.btn-primary span {
    color: #fff !important;
  }
  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    background-color: #e1dfdf;
  }
  .ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role="button"]:hover .ant-steps-item-icon {
    border-color: #f15f8d  !important;
  }
  .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role="button"]:hover .ant-steps-item-title, .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role="button"]:hover .ant-steps-item-subtitle, .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role="button"]:hover .ant-steps-item-description {
    color: #f15f8d !important;
  }
  .ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role="button"]:hover .ant-steps-item-icon .ant-steps-icon {
    color: #f15f8d !important;
  }
  .create-form-user .header-table-3.row .label-check {
    padding: 5px 20px;
  }
  .steps-action {
    text-align: right;
  }
  .create-form-user  .cotnent-table div{padding-top: 5px;padding-bottom:5px}
  .steps-content {
    // height: 300px;
    padding: 15px;
    border: 1px solid #ddd;
    margin: 15px 0px;
    border-radius: 4px;
    // height: 370px;
  }
  .field-row .ant-picker {
    width: 100%;
    height: 40px;
    border-radius: 4px;
  }
  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: #f15f8d;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    bottom: 0;
    height: 22px;
  }
  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon svg {
    fill: #f15f8d !important;
  }

 .sucessModal svg {
    width: 100px;
    display: block;
    margin: 40px auto 0;
    }
    
    .path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 0;
    &.circle {
    -webkit-animation: dash .9s ease-in-out;
    animation: dash .9s ease-in-out;
    }
    &.line {
    stroke-dashoffset: 1000;
    -webkit-animation: dash .9s .35s ease-in-out forwards;
    animation: dash .9s .35s ease-in-out forwards;
    }
    &.check {
    stroke-dashoffset: -100;
    -webkit-animation: dash-check .9s .35s ease-in-out forwards;
    animation: dash-check .9s .35s ease-in-out forwards;
    }
    }
      @-webkit-keyframes dash {
        0% {
        stroke-dashoffset: 1000;
        }
        100% {
        stroke-dashoffset: 0;
        }
        }
        
        @keyframes dash {
        0% {
        stroke-dashoffset: 1000;
        }
        100% {
        stroke-dashoffset: 0;
        }
        }
        
        @-webkit-keyframes dash-check {
        0% {
        stroke-dashoffset: -100;
        }
        100% {
        stroke-dashoffset: 900;
        }
        }
        
        @keyframes dash-check {
        0% {
        stroke-dashoffset: -100;
        }
        100% {
        stroke-dashoffset: 900;
        }
        
      }
      .sucessModal{ 
        text-align: center;
        h4 {
          text-align: center;
          margin-top: 20px;
        }    
        .btn.btn-close {
          width: 100px;
          margin-top: 10px;
        }
      }
      .ant-steps.ant-steps-horizontal.ant-steps-label-horizontal {
        max-width: 35%;
        float: none;
        margin: 0 auto;
      }    
      .sucessModal h4 strong {
        color: #f15f8d;
        font-weight: normal;
      }
      .field-row label strong {
        color: #f15e8c;
        font-weight: 600;
      }